import { Buffer } from "buffer";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useOpenState } from "@vrize/vrizead-use";
import { useSnackbar } from "notistack";
import { ChangeEventHandler, VFC, useCallback, useMemo, useState } from "react";
import { FragmentRef, graphql } from "relay-runtime";

import {
  CampaignDomainRestrictionListCard_campaign,
  CampaignDomainRestrictionStatus,
} from "~/__relay_artifacts__/CampaignDomainRestrictionListCard_campaign.graphql";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import {
  CreateCampaignDomainRestrictionInput,
  createCampaignDomainRestrictionMutation,
} from "~/mutations/CreateCampaignDomainRestrictionMutation";
import { deleteCampaignDomainRestrictionMutation } from "~/mutations/DeleteCampaignDomainRestrictionMutation";

import { ListTable } from "../ListTable";

type Props = {
  campaignRef: FragmentRef<CampaignDomainRestrictionListCard_campaign>;
};

const fragment = graphql`
  fragment CampaignDomainRestrictionListCard_campaign on Campaign {
    id
    campaignDomainRestrictions(first: 150)
      @connection(
        key: "CampaignDomainRestrictionListCard_campaignDomainRestrictions"
      ) {
      __id
      edges {
        node {
          id
          domain
          status
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  paper: { margin: theme.spacing(3) },
}));

export const CampaignDomainRestrictionListCard: VFC<Props> = ({
  campaignRef,
}) => {
  const classes = useStyles();
  const [alertDialogOpen, openAlertDialog, closeAlertDialog] = useOpenState();
  const [formDialogOpen, openFormDialog, closeFormDialog] = useOpenState();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);

  const campaign = useFragment<CampaignDomainRestrictionListCard_campaign>(
    fragment,
    campaignRef
  );

  const campaignDomainRestrictions = useMemo(() => {
    const edges = campaign.campaignDomainRestrictions.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge?.node;
    });
  }, [campaign.campaignDomainRestrictions.edges, page, perPage]);

  const handleCreate = useCallback<CreateDialogProps["onCreate"]>(
    async (input) => {
      try {
        await createCampaignDomainRestrictionMutation(
          environment,
          {
            ...input,
            campaignId: campaign.id,
          },
          campaign.campaignDomainRestrictions.__id
        );
        enqueueSnackbar("配信可否ドメインを追加しました", {
          variant: "success",
        });
        closeFormDialog();
      } catch (err) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [
      environment,
      campaign.id,
      campaign.campaignDomainRestrictions.__id,
      enqueueSnackbar,
      closeFormDialog,
    ]
  );

  const handleDelete = useCallback(async () => {
    try {
      await deleteCampaignDomainRestrictionMutation(
        environment,
        deleteId,
        campaign.campaignDomainRestrictions.__id
      );
      enqueueSnackbar("配信可否ドメインを削除しました", {
        variant: "success",
      });
      closeAlertDialog();
      setDeleteId("");
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }, [
    environment,
    deleteId,
    campaign.campaignDomainRestrictions.__id,
    enqueueSnackbar,
    closeAlertDialog,
  ]);

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant="subtitle1">配信可否ドメイン設定</Typography>
        <Button onClick={openFormDialog}>追加</Button>
      </Toolbar>
      <ListTable minWidth={500}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>ドメイン</TableCell>
            <TableCell>可否</TableCell>
            <TableCell align="center">アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaignDomainRestrictions.map((restriction) => (
            <TableRow key={restriction.id}>
              <TableCell>
                {Buffer.from(restriction.id, "base64").toString()}
              </TableCell>
              <TableCell>{restriction.domain}</TableCell>
              <TableCell>{restriction.status}</TableCell>
              <TableCell align="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    openAlertDialog();
                    setDeleteId(restriction.id);
                  }}
                >
                  削除
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={(campaign.campaignDomainRestrictions.edges || []).length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      <DeleteDialog
        isOpen={alertDialogOpen}
        onDelete={handleDelete}
        onClose={closeAlertDialog}
      />
      <CreateDialog
        isOpen={formDialogOpen}
        onCreate={handleCreate}
        onClose={closeFormDialog}
      />
    </Paper>
  );
};

type CreateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (
    input: Omit<CreateCampaignDomainRestrictionInput, "campaignId">
  ) => void;
};

const CreateDialog: VFC<CreateDialogProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const [domainText, setDomainText] = useState("");
  const [domainValidation, setDomainValidation] = useState(false);
  const [status, setStatus] =
    useState<CampaignDomainRestrictionStatus>("ALLOW");

  const handleDomainTextChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      setDomainText(e.currentTarget.value);
      if (domainText !== "") setDomainValidation(false);
    },
    [domainText]
  );

  const handleStatusChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const v = event.currentTarget.value;
      if (v === "ALLOW" || v === "DENY") setStatus(v);
    },
    []
  );

  const handleCreate = useCallback(() => {
    if (domainText === "") {
      setDomainValidation(false);
    } else {
      onCreate({ status, domain: domainText });
      setDomainText("");
    }
  }, [domainText, onCreate, status]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>配信可否ドメインの追加</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="ドメイン"
            value={domainText}
            error={domainValidation}
            onChange={handleDomainTextChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel component="legend">可否</FormLabel>
          <RadioGroup
            row
            aria-label="status"
            name="status"
            value={status}
            onChange={handleStatusChange}
          >
            <FormControlLabel value="ALLOW" control={<Radio />} label="許可" />
            <FormControlLabel value="DENY" control={<Radio />} label="拒否" />
          </RadioGroup>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCreate}>
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DeleteDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteDialog: VFC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onDelete,
}) => {
  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>配信可否設定を削除しますか？</DialogTitle>
        <DialogActions>
          <Button autoFocus color="primary" onClick={onClose}>
            キャンセル
          </Button>
          <Button color="secondary" onClick={onDelete}>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
