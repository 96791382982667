import { Buffer } from "buffer";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useOpenState } from "@vrize/vrizead-use";
import { useSnackbar } from "notistack";
import { ChangeEventHandler, VFC, useCallback, useMemo, useState } from "react";
import { FragmentRef, graphql } from "relay-runtime";

import { CampaignAllowIpListCard_campaign } from "~/__relay_artifacts__/CampaignAllowIpListCard_campaign.graphql";
import { useFragment, useRelayEnvironment } from "~/lib/relay-hooks";
import { createCampaignAllowIpMutation } from "~/mutations/CreateCampaignAllowIpMutation";
import { deleteCampaignAllowIpMutation } from "~/mutations/DeleteCampaignAllowIpMutation";

import { ListTable } from "../ListTable";

type Props = {
  campaignRef: FragmentRef<CampaignAllowIpListCard_campaign>;
};

const fragment = graphql`
  fragment CampaignAllowIpListCard_campaign on Campaign {
    id
    campaignAllowIps(first: 150)
      @connection(key: "CampaignAllowIpListCard_campaignAllowIps") {
      __id
      edges {
        node {
          id
          ip
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  paper: { margin: theme.spacing(3) },
}));

export const CampaignAllowIPListCard: VFC<Props> = ({ campaignRef }) => {
  const classes = useStyles();
  const [alertDialogOpen, openAlertDialog, closeAlertDialog] = useOpenState();
  const [formDialogOpen, openFormDialog, closeFormDialog] = useOpenState();
  const { enqueueSnackbar } = useSnackbar();
  const environment = useRelayEnvironment();
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);

  const campaign = useFragment<CampaignAllowIpListCard_campaign>(
    fragment,
    campaignRef
  );

  const campaignAllowIps = useMemo(() => {
    const edges = campaign.campaignAllowIps?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion error");
      return edge?.node;
    });
  }, [campaign.campaignAllowIps, page, perPage]);

  const handleCreate = useCallback<CreateDialogProps["onCreate"]>(
    async (input) => {
      try {
        await createCampaignAllowIpMutation(
          environment,
          {
            ...input,
            campaignId: campaign.id,
          },
          campaign.campaignAllowIps.__id,
          "CampaignAllowIpEdge"
        );
        enqueueSnackbar("配信先IPを追加しました", { variant: "success" });
        closeFormDialog();
      } catch (err: any) {
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [
      environment,
      campaign.id,
      campaign.campaignAllowIps,
      enqueueSnackbar,
      closeFormDialog,
    ]
  );
  const handleDelete = useCallback(async () => {
    try {
      await deleteCampaignAllowIpMutation(
        environment,
        deleteId,
        campaign.campaignAllowIps.__id
      );
      enqueueSnackbar("配信先IPを削除しました", {
        variant: "success",
      });
      closeAlertDialog();
      setDeleteId("");
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  }, [
    campaign.campaignAllowIps,
    closeAlertDialog,
    deleteId,
    enqueueSnackbar,
    environment,
  ]);

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant="subtitle1">配信先IP</Typography>
        <Button onClick={openFormDialog}>追加</Button>
      </Toolbar>
      <ListTable minWidth={500}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>IP</TableCell>
            <TableCell align="center">アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaignAllowIps.map((allowIp) => (
            <TableRow key={allowIp.id}>
              <TableCell>
                {Buffer.from(allowIp.id, "base64").toString()}
              </TableCell>
              <TableCell>{allowIp.ip}</TableCell>
              <TableCell align="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    openAlertDialog();
                    setDeleteId(allowIp.id);
                  }}
                >
                  削除
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={(campaign.campaignAllowIps?.edges || []).length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
      <DeleteDialog
        isOpen={alertDialogOpen}
        onDelete={handleDelete}
        onClose={closeAlertDialog}
      />
      <CreateDialog
        isOpen={formDialogOpen}
        onCreate={handleCreate}
        onClose={closeFormDialog}
      />
    </Paper>
  );
};

type CreateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (input: { ip: string }) => void;
};

const CreateDialog: VFC<CreateDialogProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const [ip, setIP] = useState("");
  const [targetIPValidation, setTargetIPValidation] = useState(false);

  const handleTargetIPChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      setIP(e.currentTarget.value);
      if (ip !== "") setTargetIPValidation(false);
    },
    [ip]
  );

  const handleCreate = useCallback(() => {
    if (ip === "") {
      setTargetIPValidation(false);
    } else {
      onCreate({ ip });
      setIP("");
    }
  }, [ip, onCreate]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>配信先IPの追加</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="IP"
            value={ip}
            error={targetIPValidation}
            onChange={handleTargetIPChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCreate}>
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type DeleteDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteDialog: VFC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onDelete,
}) => {
  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>配信先IPを削除しますか？</DialogTitle>
        <DialogActions>
          <Button autoFocus color="primary" onClick={onClose}>
            キャンセル
          </Button>
          <Button color="secondary" onClick={onDelete}>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
