/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCategory = "COMIC_BOOKS" | "DRAWING_SKETCHING" | "VIDEO_COMPUTER_GAMES" | "%future added value";
export type BidStrategy = "MANUAL" | "ML" | "%future added value";
export type CampaignReportingOption = "CPI" | "CPM" | "SIMEJI" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type CampaignListTableCardForAdmin_root = {
    readonly campaigns: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly status: CampaignStatus;
                readonly bidStrategy: BidStrategy;
                readonly reportingOption: CampaignReportingOption;
                readonly periodSince: string;
                readonly periodTill: string | null;
                readonly goalAmount: number;
                readonly frequencyCapEnabled: boolean;
                readonly archived: boolean;
                readonly os: Os | null;
                readonly isBanditEnabled: boolean;
                readonly latestVersion: number | null;
                readonly categories: ReadonlyArray<AdCategory>;
                readonly project: {
                    readonly id: string;
                };
                readonly " $fragmentRefs": FragmentRefs<"CampaignEditFormForAdmin_campaign">;
            } | null;
        } | null> | null;
        readonly totalCount: number;
    };
    readonly " $refType": "CampaignListTableCardForAdmin_root";
};
export type CampaignListTableCardForAdmin_root$data = CampaignListTableCardForAdmin_root;
export type CampaignListTableCardForAdmin_root$key = {
    readonly " $data"?: CampaignListTableCardForAdmin_root$data;
    readonly " $fragmentRefs": FragmentRefs<"CampaignListTableCardForAdmin_root">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "withArchived"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "campaigns"
        ]
      }
    ]
  },
  "name": "CampaignListTableCardForAdmin_root",
  "selections": [
    {
      "alias": "campaigns",
      "args": null,
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "__CampaignListTableCard_campaigns_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bidStrategy",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reportingOption",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "periodSince",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "periodTill",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "goalAmount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "frequencyCapEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "os",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isBanditEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "latestVersion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "categories",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CampaignEditFormForAdmin_campaign"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '78e15e424d78c987374d34f09b02ea9f';
export default node;
