import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { FormikDateField, FormikNumberField } from "@vrize/vrizead-use";
import * as DateFns from "date-fns";
import { FormikProps } from "formik";
import * as React from "react";
import { graphql } from "react-relay";
import { useQuery } from "relay-hooks";
import * as yup from "yup";

import { ExpenseUpsertForm_Query } from "~/__relay_artifacts__/ExpenseUpsertForm_Query.graphql";

import { DialogForm } from "./DialogForm";

export type FormValues = yup.Asserts<typeof schema>;

type Props = {
  campaignId: string;
  campaignTitle: string;
  selectableBeforeDate: boolean;
} & FormikProps<FormValues>;

export const schema = yup.object({
  date: yup.date().required(),
  rate: yup.number().nullable(),
  maxAssignableExpenseRate: yup.number().nullable(),
});

const query = graphql`
  query ExpenseUpsertForm_Query($campaignId: ID!, $date: ISO8601Date!) {
    campaign(id: $campaignId) {
      maxAssignableExpenseRate(date: $date)
      expense(date: $date) {
        id
        rate
      }
    }
  }
`;

export const ExpenseUpsertForm: React.FC<Props> = ({
  campaignId,
  campaignTitle,
  selectableBeforeDate,
  values,
  setFieldValue,
  submitForm,
}) => {
  const currentDateTime = new Date();
  const { props } = useQuery<ExpenseUpsertForm_Query>(query, {
    campaignId,
    date: new Date(values.date).toISOString(),
  });

  const maxAssignableExpenseRate = React.useMemo(() => {
    if (!props) return null;
    if (!props.campaign) return null;
    values.maxAssignableExpenseRate = props.campaign.maxAssignableExpenseRate;
    return props.campaign.maxAssignableExpenseRate;
  }, [props, values]);

  const expense = React.useMemo(() => {
    if (!props) return null;
    if (!props.campaign) return null;
    return props.campaign.expense;
  }, [props]);

  React.useEffect(() => {
    if (!expense) return;
    setFieldValue("rate", expense.rate);
  }, [setFieldValue, expense]);

  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  const handleSubmit = () => {
    if (values.rate === 0) {
      setIsConfirmOpen(true);
    } else {
      submitForm();
    }
  };

  return (
    <>
      <DialogForm onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography>{atob(campaignId)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{campaignTitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            {selectableBeforeDate ? (
              <FormikDateField
                required
                fullWidth
                name="date"
                label="日付"
                minDate={DateFns.startOfYesterday()}
                maxDate={currentDateTime}
              />
            ) : (
              <FormikDateField
                required
                fullWidth
                readOnly
                name="date"
                label="日付"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <FormikNumberField
              required
              fullWidth
              name="rate"
              label="追加手数料(率)"
              margin="normal"
              precision={2}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">設定可能な最大手数料(率)</Typography>
            <Typography>
              {maxAssignableExpenseRate
                ? maxAssignableExpenseRate
                : "算出できませんでした"}
            </Typography>
          </Grid>
        </Grid>
      </DialogForm>

      <Dialog open={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
        <DialogTitle>確認</DialogTitle>
        <DialogContent>追加手数料0で設定してよろしいですか？</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => {
              setIsConfirmOpen(false);
              submitForm();
            }}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
