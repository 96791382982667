import {
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { createPaginationContainer, graphql } from "react-relay";

import { MembershipList_project } from "~/__relay_artifacts__/MembershipList_project.graphql";
import { MembershipList_viewer } from "~/__relay_artifacts__/MembershipList_viewer.graphql";
import { MembershipCreateForm } from "~/containers/MembershipCreateForm";
import { MembershipDeleteButton } from "~/containers/MembershipDeleteButton";
import { MembershipRoleEditForm } from "~/containers/MembershipRoleEditForm";
import { parseISO } from "~/lib/parseISO";

import { DialogButton } from "./DialogButton";
import { ListTable } from "./ListTable";

type Props = {
  project: MembershipList_project;
  viewer: MembershipList_viewer;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  title: {
    flex: "0 0 auto",
  },
  actions: {
    display: "flex",
  },
}));

const MembershipList: React.FC<Props> = ({ project, viewer }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(100);

  const memberships = React.useMemo(() => {
    const edges = project.memberships?.edges || [];
    const filteredEdges = edges
      .map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion failed");
        return {
          ...node,
          isEditable:
            project.viewerCanAdministrate && node.user.id !== viewer.id,
        };
      })
      .filter((node) =>
        // NOTE: 閲覧者がADMINの権限を持っていない場合は他のADMINユーザを表示しない
        viewer.role !== "ADMIN" && node.role === "ADMIN" ? false : true
      );
    const from = page * perPage;
    const to = page * perPage + perPage;
    return filteredEdges.slice(from, to);
  }, [
    project.memberships,
    project.viewerCanAdministrate,
    viewer.id,
    viewer.role,
    page,
    perPage,
  ]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <div className={classes.title}>
          <Typography variant="subtitle1" color="inherit">
            メンバー一覧
          </Typography>
        </div>
        <div className={classes.actions}>
          {project.viewerCanAdministrate && (
            <DialogButton
              title="メンバー作成"
              minWidth={400}
              render={({ close }) => (
                <MembershipCreateForm onSubmitCompleted={close} />
              )}
            >
              <Icon>add</Icon> 新規作成
            </DialogButton>
          )}
        </div>
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>役割</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberships.map((membership) => (
            <TableRow key={membership.id} hover>
              <TableCell>{membership.user.email}</TableCell>
              <TableCell>
                {membership.isEditable ? (
                  <DialogButton
                    title="メンバー役割編集"
                    minWidth={400}
                    variant="outlined"
                    render={({ close }) => (
                      <MembershipRoleEditForm
                        membershipRef={membership}
                        onSubmitCompleted={close}
                      />
                    )}
                  >
                    {membership.role}
                  </DialogButton>
                ) : (
                  membership.role
                )}
              </TableCell>
              <TableCell>{parseISO(membership.createdAt)}</TableCell>
              <TableCell>
                {membership.isEditable && (
                  <MembershipDeleteButton
                    membershipId={membership.id}
                    projectId={project.id}
                  >
                    削除
                  </MembershipDeleteButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={(project.memberships?.edges || []).length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  MembershipList,
  {
    project: graphql`
      fragment MembershipList_project on Project {
        id
        viewerCanAdministrate
        memberships(first: $first, after: $after)
          @connection(key: "MembershipList_memberships", filters: []) {
          totalCount
          edges {
            node {
              id
              role
              createdAt
              user {
                id
                email
              }
              ...MembershipRoleEditForm_membership
            }
          }
        }
      }
    `,
    viewer: graphql`
      fragment MembershipList_viewer on User {
        id
        email
        role
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.project.memberships as any;
    },
    getVariables({ project }, { count, cursor }) {
      return {
        projectId: project.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query MembershipList_Query($projectId: ID!, $first: Int, $after: String) {
        viewer {
          ...MembershipList_viewer
        }
        project(id: $projectId) {
          ...MembershipList_project
        }
      }
    `,
  }
);
