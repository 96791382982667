import {
  DialogContent,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { AccountCircle as AccountCircleIcon } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { useOpenState } from "@vrize/vrizead-use";
import firebase from "firebase/app";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import * as yup from "yup";

import { AccountButton_viewer } from "~/__relay_artifacts__/AccountButton_viewer.graphql";
import { ResponsiveDialog } from "~/components/ResponsiveDialog";
import { useFragment } from "~/lib/relay-hooks";

import { AccountEmailForm } from "./AccountEmailForm";
import { AccountPasswordForm } from "./AccountPasswordForm";

type Props = {
  viewerRef: FragmentRef<any>;
};

const validationSchema = yup.object({
  email: yup.string().required(),
});

const passwordValidationSchema = yup.object({
  password: yup.string().required().min(8, "パスワードは最低8文字です。"),
});

const viewerFragment = graphql`
  fragment AccountButton_viewer on User {
    id
    email
  }
`;

const StyledDialogContent = styled(DialogContent)({
  minWidth: 300,
});

export const AccountButton: React.FC<Props> = ({ viewerRef }) => {
  const [dialogOpen, openDialog, closeDialog] = useOpenState();
  const { enqueueSnackbar } = useSnackbar();
  const viewer = useFragment<AccountButton_viewer>(viewerFragment, viewerRef);

  const handleListItemClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const handleUpdateAccountSubmit = React.useCallback(
    async (values, { setErrors }) => {
      try {
        if (!firebase.auth) throw new Error("firebase.auth is not set");
        const currentUser = firebase.auth().currentUser;
        if (!currentUser) return Promise.reject();
        await currentUser.updateEmail(values.email);
      } catch (err) {
        setErrors(err);
      }
    },
    []
  );

  const handleUpdatePasswordSubmit = React.useCallback(
    async (values, { setErrors }) => {
      try {
        if (!firebase.auth) throw new Error("firebase.auth is not set");
        const currentUser = firebase.auth().currentUser;
        if (!currentUser) return Promise.reject();
        await currentUser.updatePassword(values.password);
        enqueueSnackbar("パスワードを更新しました", { variant: "success" });
        openDialog();
      } catch (err) {
        enqueueSnackbar(
          "再認証が必要です。もう一度ログインしてから操作をやり直してください。",
          { variant: "error" }
        );
        setErrors(err);
      }
    },
    [enqueueSnackbar, openDialog]
  );

  return (
    <>
      <ListItem button onClick={handleListItemClick}>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={viewer.email} />
      </ListItem>
      <ResponsiveDialog
        title="アカウント編集"
        minWidth={400}
        open={dialogOpen}
        onClose={closeDialog}
      >
        <StyledDialogContent>
          <Formik
            initialValues={{ email: viewer.email }}
            validationSchema={validationSchema}
            render={AccountEmailForm}
            onSubmit={handleUpdateAccountSubmit}
          />
          <Formik
            initialValues={{ password: "" }}
            validationSchema={passwordValidationSchema}
            render={AccountPasswordForm}
            onSubmit={handleUpdatePasswordSubmit}
          />
        </StyledDialogContent>
      </ResponsiveDialog>
    </>
  );
};
