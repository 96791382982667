import {
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { createPaginationContainer, graphql } from "react-relay";

import { ScheduleSetList_project } from "~/__relay_artifacts__/ScheduleSetList_project.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { ScheduleSetCreateForm } from "~/containers/ScheduleSetCreateForm";
import { ScheduleSetDeleteButton } from "~/containers/ScheduleSetDeleteButton";
import { ScheduleSetEditForm } from "~/containers/ScheduleSetEditForm";
import { parseISO } from "~/lib/parseISO";

import { ScheduleListButton } from "./ScheduleListButton";
import { ScheduleSetAdListButton } from "./ScheduleSetAdListButton";

type Props = {
  project: ScheduleSetList_project;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
}));

const ScheduleSetList: React.FC<Props> = ({ project }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);

  const scheduleSets = React.useMemo(() => {
    const edges = project.scheduleSets?.edges;
    if (!edges) throw new Error("assertion failed");
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [project.scheduleSets, page, perPage]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          スケジュールセット一覧
        </Typography>
        <DialogButton
          title="スケジュールセット作成"
          render={({ close }) => (
            <ScheduleSetCreateForm onSubmitCompleted={close} />
          )}
        >
          <Icon>add</Icon> 新規作成
        </DialogButton>
      </Toolbar>
      <ListTable minWidth={900}>
        <TableHead>
          <TableRow>
            <TableCell>名前</TableCell>
            <TableCell>登録スケジュール</TableCell>
            <TableCell>対象の広告</TableCell>
            <TableCell>作成日</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduleSets.map((scheduleSet) => (
            <TableRow key={scheduleSet.id}>
              <TableCell>{scheduleSet.title}</TableCell>
              <TableCell>
                <ScheduleListButton scheduleSetRef={scheduleSet} />
              </TableCell>
              <TableCell>
                <ScheduleSetAdListButton
                  scheduleSetRef={scheduleSet}
                  projectId={project.id}
                />
              </TableCell>
              <TableCell>{parseISO(scheduleSet.createdAt)}</TableCell>
              <TableCell>
                <DialogButton
                  title="スケジュールセット編集"
                  variant="outlined"
                  color="primary"
                  render={({ close }) => (
                    <ScheduleSetEditForm
                      scheduleSetRef={scheduleSet}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ScheduleSetDeleteButton
                  scheduleSetId={scheduleSet.id}
                  projectId={project.id}
                >
                  削除
                </ScheduleSetDeleteButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={(project.scheduleSets?.edges || []).length}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  ScheduleSetList,
  {
    project: graphql`
      fragment ScheduleSetList_project on Project {
        id
        scheduleSets(first: $first, after: $after)
          @connection(key: "ScheduleSetList_scheduleSets", filters: []) {
          totalCount
          edges {
            node {
              id
              title
              createdAt
              ...ScheduleListButton_scheduleSet
              ...ScheduleSetAdListButton_scheduleSet
              ...ScheduleSetEditForm_scheduleSet
            }
          }
        }
      }
    `,
  },
  {
    getConnectionFromProps(props) {
      return props.project.scheduleSets as any;
    },
    getVariables(props, { count, cursor }) {
      return {
        projectId: props.project.id,
        first: count,
        after: cursor,
      };
    },
    query: graphql`
      query ScheduleSetList_Query(
        $projectId: ID!
        $first: Int
        $after: String
      ) {
        project(id: $projectId) {
          ...ScheduleSetList_project
        }
      }
    `,
  }
);
