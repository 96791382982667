import {
  FormControlLabel,
  Paper,
  Switch,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as React from "react";
import {
  RelayPaginationProp,
  createPaginationContainer,
  graphql,
} from "react-relay";
import { Link } from "react-router-dom";

import { CampaignListTableCardForAdmin_root } from "~/__relay_artifacts__/CampaignListTableCardForAdmin_root.graphql";
import { DialogButton } from "~/components/DialogButton";
import { ListTable } from "~/components/ListTable";
import { TruncatedTextWithTooltip } from "~/components/TruncatedTextWithTooltip";
import { TableCell } from "~/components/atoms/TableCell";
import { ExpenseUpsertForm } from "~/containers/ExpenseUpsertForm";
import { noop } from "~/lib/noop";

import { ArchiveButton } from "./ArchiveButton";
import { CampaignEditFormForAdmin } from "./CampaignFormsForAdmin";
import { StatusSwitch } from "./StatusSwitch";

type Props = {
  relay: RelayPaginationProp;
  root: CampaignListTableCardForAdmin_root;
};

const useStyles = makeStyles({
  root: {
    marginTop: 24,
  },
  title: {
    width: 100,
  },
});

const CampaignListTableCardForAdmin: React.FC<Props> = ({ relay, root }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);
  const [withArchived, setWithArchived] = React.useState(false);
  const totalCount = root.campaigns.totalCount;
  const currentDateTime = new Date();
  const limitDateTime = DateFns.getTime(
    new Date(
      DateFns.getYear(currentDateTime),
      DateFns.getMonth(currentDateTime),
      DateFns.getDate(currentDateTime),
      9
    )
  );

  const campaigns = React.useMemo(() => {
    const edges = root.campaigns.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [page, perPage, root.campaigns.edges]);

  const handleWithArchivedChange = React.useCallback(() => {
    setWithArchived(!withArchived);
    relay.refetchConnection(perPage, noop, { withArchived: !withArchived });
  }, [perPage, relay, withArchived]);

  const handleArchiveCompleted = React.useCallback(() => {
    relay.refetchConnection(perPage, noop, { withArchived });
  }, [perPage, relay, withArchived]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
        >
          キャンペーン管理
        </Typography>
        <FormControlLabel
          label="アーカイブ済みデータを含める"
          control={
            <Switch
              checked={withArchived}
              onChange={handleWithArchivedChange}
            />
          }
        />
      </Toolbar>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>OS</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => (
            <TableRow key={campaign.id}>
              <TableCell>
                {atob(campaign.id).replace("Campaign-", "")}
              </TableCell>
              <TableCell>
                <Link
                  to={`/projects/${campaign.project.id}/campaigns/${campaign.id}`}
                >
                  <TruncatedTextWithTooltip tooltip={campaign.title}>
                    {campaign.title}
                  </TruncatedTextWithTooltip>
                </Link>
              </TableCell>
              <TableCell>
                <Tooltip title={campaign.status}>
                  <div role="presentation">
                    <StatusSwitch campaign={campaign} />
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>{campaign.os}</TableCell>
              <TableCell>
                <DialogButton
                  variant="outlined"
                  color="inherit"
                  title="追加費用"
                  render={({ close }) => (
                    <ExpenseUpsertForm
                      campaignId={campaign.id}
                      campaignTitle={campaign.title}
                      onSubmitCompleted={close}
                      selectableBeforeDate={
                        DateFns.getTime(currentDateTime) < limitDateTime
                      }
                    />
                  )}
                >
                  追加費用
                </DialogButton>
                <DialogButton
                  variant="outlined"
                  color="primary"
                  title="キャンペーン編集"
                  render={({ close }) => (
                    <CampaignEditFormForAdmin
                      campaignRef={campaign}
                      onSubmitCompleted={close}
                    />
                  )}
                >
                  編集
                </DialogButton>
                <ArchiveButton
                  campaign={campaign}
                  onSubmitCompleted={handleArchiveCompleted}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={perPage}
        page={page}
        onPageChange={(_, page) => {
          relay.hasMore() && relay.loadMore(perPage, noop);
          setPage(page);
        }}
        onRowsPerPageChange={(e) => setPerPage(parseInt(e.target.value))}
      />
    </Paper>
  );
};

export default createPaginationContainer(
  CampaignListTableCardForAdmin,
  {
    root: graphql`
      fragment CampaignListTableCardForAdmin_root on Query {
        campaigns(
          first: $first
          after: $after
          orderBy: { field: ID, direction: DESC }
          withArchived: $withArchived
        ) @connection(key: "CampaignListTableCard_campaigns", filters: []) {
          edges {
            node {
              ...CampaignEditFormForAdmin_campaign
              id
              title
              status
              bidStrategy
              reportingOption
              periodSince
              periodTill
              goalAmount
              frequencyCapEnabled
              archived
              os
              isBanditEnabled
              latestVersion
              categories
              project {
                id
              }
            }
          }
          totalCount
        }
      }
    `,
  },
  {
    getConnectionFromProps: (props) => props.root.campaigns as any,
    getVariables: (_, { count, cursor }) => ({
      first: count,
      after: cursor,
    }),
    query: graphql`
      query CampaignListTableCardForAdmin_Query(
        $first: Int
        $after: String
        $withArchived: Boolean
      ) {
        ...CampaignListTableCardForAdmin_root
      }
    `,
  }
);
