import {
  Button,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as DateFns from "date-fns";
import * as R from "ramda";
import * as React from "react";
import { FragmentRef, graphql } from "react-relay";
import { useParams } from "react-router";

import { ClientDailyBudgetListTableCard_Query } from "~/__relay_artifacts__/ClientDailyBudgetListTableCard_Query.graphql";
import { ClientDailyBudgetListTableCard_campaign } from "~/__relay_artifacts__/ClientDailyBudgetListTableCard_campaign.graphql";
import { ListTable } from "~/components/ListTable";
import { useDailyBudgetByMonthCache } from "~/globalState";
import { useRefetch } from "~/lib/relay-hooks";

const fragment = graphql`
  fragment ClientDailyBudgetListTableCard_campaign on Campaign {
    id
    dailyBudgets(first: 100, month: $month)
      @connection(key: "DailyBudgetListTableCard_dailyBudgets") {
      edges {
        node {
          id
          date
          baseAmount
          ...DailyBudgetEditForm_dailyBudget
        }
      }
      totalCount
    }
  }
`;

const query = graphql`
  query ClientDailyBudgetListTableCard_Query(
    $projectId: ID!
    $campaignId: ID!
    $month: ISO8601Date!
  ) {
    project(id: $projectId) {
      campaign(id: $campaignId) {
        ...ClientDailyBudgetListTableCard_campaign
      }
    }
  }
`;

type Props = {
  campaignRef: FragmentRef<ClientDailyBudgetListTableCard_campaign>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const useDailyBudgets = (
  campaignRef: Props["campaignRef"],
  projectId: string
) => {
  const [campaign, refetch] =
    useRefetch<ClientDailyBudgetListTableCard_campaign>(fragment, campaignRef);

  const refetchDailyBudgets = React.useCallback(
    (month: Date) => {
      const variables = {
        projectId,
        campaignId: campaign.id,
        month: month.toISOString(),
      };
      refetch<ClientDailyBudgetListTableCard_Query>(query, variables);
    },
    [campaign.id, projectId, refetch]
  );

  return { campaign, refetchDailyBudgets };
};

export const ClientDailyBudgetListTableCard: React.FC<Props> = ({
  campaignRef,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(100);

  const { campaign, refetchDailyBudgets } = useDailyBudgets(
    campaignRef,
    projectId
  );

  const [cache, setCache] = useDailyBudgetByMonthCache(projectId, campaign.id);
  const [month, setMonth] = React.useState<Date>(
    DateFns.parseISO(cache.month) || new Date()
  );

  const dailyBudgets = React.useMemo(() => {
    const edges = campaign.dailyBudgets?.edges || [];
    const from = page * perPage;
    const to = page * perPage + perPage;
    return edges.slice(from, to).map((edge) => {
      if (!edge?.node) throw new Error("assertion failed");
      return edge.node;
    });
  }, [campaign.dailyBudgets, page, perPage]);

  const handleArrowBackSubmit = React.useCallback(async () => {
    const targetMonth = DateFns.subMonths(month, 1);
    await refetchDailyBudgets(targetMonth);
    setMonth(targetMonth);
    setCache({ month: targetMonth.toISOString() });
  }, [month, refetchDailyBudgets, setCache]);

  const handleArrowForwardSubmit = React.useCallback(async () => {
    const targetMonth = DateFns.addMonths(month, 1);
    await refetchDailyBudgets(targetMonth);
    setMonth(targetMonth);
    setCache({ month: targetMonth.toISOString() });
  }, [month, refetchDailyBudgets, setCache]);

  return (
    <Paper className={classes.root}>
      <Toolbar>
        <Typography variant="subtitle1" color="inherit">
          日予算一覧
        </Typography>
        <Button onClick={handleArrowBackSubmit}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="subtitle1" color="inherit">
          {R.slice(0, 7, month.toISOString())}
        </Typography>
        <Button onClick={handleArrowForwardSubmit}>
          <ArrowForwardIcon />
        </Button>
      </Toolbar>
      <ListTable minWidth={700}>
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>予算</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dailyBudgets.map((budget) => (
            <TableRow key={budget.id}>
              <TableCell>{budget.date}</TableCell>
              <TableCell>{budget.baseAmount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </ListTable>
      <div className={classes.pagination}>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          count={(campaign.dailyBudgets?.edges || []).length}
          rowsPerPage={perPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </div>
    </Paper>
  );
};
